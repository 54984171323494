import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const Skills = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    const accordionItems = document.querySelectorAll(".accordion-item");
    accordionItems.forEach((item, index) => {
      const collapseElement = item.querySelector(".accordion-collapse");
      if (collapseElement) {
        console.log("find collapse");
      }
      collapseElement.addEventListener("hidden.bs.collapse", function () {
        console.log("into collapse");
        if (activeIndex === index) {
          setActiveIndex(null);
        }
      });

      collapseElement.addEventListener("shown.bs.collapse", function () {
        setActiveIndex(index);
      });
    });
    return () => {
      accordionItems.forEach((item) => {
        const collapseElement = item.querySelector(".accordion-collapse");
        collapseElement.removeEventListener("hidden.bs.collapse", () => {});
        collapseElement.removeEventListener("shown.bs.collapse", () => {});
      });
    };
  }, [activeIndex]);

  useEffect(() => {
    const { Carousel } = require("mdb-ui-kit");
    const myCarousel = document.getElementById("carouselExampleIndicators");
    if (myCarousel) {
      new Carousel(myCarousel); // Initialize the carousel
      console.log("find");
      myCarousel.addEventListener("slide.bs.carousel", (event) => {
        console.log("slide");
      });
    }
  }, []);
  return (
    <div>
      <section
        id="skills_section_tobe_linked"
        className="skills d-flex flex-row justify-content-center align-items-center"
      >
        <div className="container">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item item1">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Teamwork Spirit
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body container">
                  <div className="card d-flex flex-column flex-lg-row">
                    <div className="col-12 col-lg-6">
                      <img
                        className="card-img-top"
                        src={require("../github_contribution.png")}
                        alt="github contribution"
                      />
                    </div>

                    <div className="card-body">
                      {/* <h5 className="card-title"></h5> */}
                      <p className="card-text">
                        In our 6-member team project, I played a pivotal role,
                        contributing significantly to the development process.
                        Throughout the project, I made a total of 68 commits,
                        which involved adding 4,290 lines of code and removing
                        2,534 lines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item item2">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Workflow
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body container">
                  <div className="card d-flex  flex-column flex-lg-row">
                    <div className="col-12 col-md-6">
                      <video style={{ width: "100%" }} controls>
                        <source
                          src={require("../readme-demo.mp4")}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="card-body">
                      {/* <h5 className="card-title"></h5> */}
                      <p className="card-text">
                        I excel at crafting clear and engaging documentation
                        that helps readers understand the core aspects of my
                        work. My documentation includes detailed descriptions of
                        app features, relevant examples, key highlights, and
                        instructions on using APIs, while addressing areas that
                        require special attention. This approach ensures readers
                        can quickly grasp the app's functionality and its unique
                        value propositions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item item3">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Software Development
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="card d-flex flex-column flex-lg-row">
                    <div className="col-12 col-lg-6">
                      <div
                        id="carouselExampleIndicators"
                        data-mdb-carousel-init
                        className="carousel slide "
                        data-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-target="#carouselExampleIndicators"
                            data-mdb-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                            style={{ backgroundColor: "black" }}
                          ></button>
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-target="#carouselExampleIndicators"
                            data-mdb-slide-to="1"
                            aria-label="Slide 2"
                            style={{ backgroundColor: "black" }}
                          ></button>
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-target="#carouselExampleIndicators"
                            data-mdb-slide-to="2"
                            aria-label="Slide 3"
                            style={{ backgroundColor: "black" }}
                          ></button>
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-target="#carouselExampleIndicators"
                            data-mdb-slide-to="3"
                            aria-label="Slide 4"
                            style={{ backgroundColor: "black" }}
                          ></button>
                        </div>
                        <div className="carousel-inner python-code">
                          <div className="carousel-item active carousel-fixed-size">
                            <img
                              className="d-block w-100"
                              src={require("../pythoncode.png")}
                              alt="First slide"
                            />
                            <div className="carousel-caption d-none d-md-block">
                              <h5
                                style={{
                                  color: "black",
                                  fontWeight: 600,
                                  marginBottom: 30,
                                }}
                              >
                                Python Code
                              </h5>
                            </div>
                          </div>
                          <div className="carousel-item carousel-fixed-size">
                            <img
                              className="d-block w-100"
                              src={require("../reactcode.png")}
                              alt="Second slide"
                            />
                            <div className="carousel-caption d-none d-md-block">
                              <h5
                                style={{
                                  color: "black",
                                  fontWeight: 600,
                                  marginBottom: 30,
                                }}
                              >
                                React Code
                              </h5>
                            </div>
                          </div>
                          <div className="carousel-item carousel-fixed-size">
                            <img
                              className="d-block w-100"
                              src={require("../nodecode.png")}
                              alt="Third slide"
                            />
                            <div className="carousel-caption d-none d-md-block">
                              <h5
                                style={{
                                  color: "black",
                                  fontWeight: 600,
                                  marginBottom: 30,
                                }}
                              >
                                Node Code
                              </h5>
                            </div>
                          </div>
                          <div className="carousel-item carousel-fixed-size">
                            <img
                              className="d-block w-100"
                              src={require("../javacode.png")}
                              alt="Fourth slide"
                            />
                            <div className="carousel-caption d-none d-md-block">
                              <h5
                                style={{
                                  color: "black",
                                  fontWeight: 600,
                                  marginBottom: 30,
                                }}
                              >
                                Java Code
                              </h5>
                            </div>
                          </div>
                        </div>
                        <button
                          data-mdb-button-init
                          className="carousel-control-prev"
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide="prev"
                          style={{ color: "black" }}
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          data-mdb-button-init
                          className="carousel-control-next"
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide="next"
                          style={{ color: "black" }}
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        Here are some snippets from my programs, showcasing
                        implementations in various programming languages.{" "}
                      </h5>
                      <ul className="language-list card-text">
                        <li className="language-list-item">Python + Flask</li>
                        <li className="language-list-item">Node + Express</li>
                        <li className="language-list-item">
                          Java + Spring boot
                        </li>
                        <li className="language-list-item">
                          Javacript (React), HTML, CSS, Bootstrap
                        </li>
                        <li className="language-list-item">TypeScript</li>
                        <li className="language-list-item">
                          TDD, Pytest, Vitest
                        </li>
                        <li className="language-list-item">RESTful API</li>
                        <li className="language-list-item">OOD/OOP</li>
                        <li className="language-list-item">GIT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item item4">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  IT Skills
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="card d-flex flex-row">
                    <div className="card-body">
                      {/* <h5 className="card-title"></h5> */}
                      <ul className="language-list card-text">
                        <li className="language-list-item">
                          Networking (DNS, DHCP, VPN, TCP/IP)
                        </li>

                        <li className="language-list-item">CI/CD</li>
                        <li className="language-list-item">
                          Web Server (Nginx/Apache)
                        </li>
                        <li className="language-list-item">
                          Visualization (VMWare, VirtualBox)
                        </li>
                        <li className="language-list-item">Linux(Ubuntu)</li>
                        <li className="language-list-item">
                          Windows Server (ADDS)
                        </li>
                        <li className="language-list-item">
                          Cloud Computing (AWS)
                        </li>
                        <li className="language-list-item">
                          Script Automation
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item item5">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Databases & Data Management
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="card d-flex flex-row">
                    <div className="card-body">
                      {/* <h5 className="card-title"></h5> */}
                      <ul className="language-list card-text">
                        <li className="language-list-item">
                          MySQL (relational)
                        </li>

                        <li className="language-list-item">
                          MongoDB (non-relational)
                        </li>
                        <li className="language-list-item">CSV</li>
                        <li className="language-list-item">JSON</li>
                        <li className="language-list-item">Power BI</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Skills;
