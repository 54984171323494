import React from "react";
import { ReactTyped } from "react-typed";

const Header = () => {
  return (
    <div className="header-wraper">
      <div className="main-info container">
        <h1 className="text-center col-12" style={{ height: "1.4rem" }}>
          Welcome &nbsp; to&nbsp; Sarah Li's&nbsp; Personal&nbsp; Portfolio!
        </h1>

        <ReactTyped
          className="typed-text"
          strings={[
            "Software Developer",
            "QA Engineer",
            "IT Support",
            "Database Administrator",
            "DevOps Engineer",
          ]}
          typeSpeed={40}
          backSpeed={50}
          loop
        />
      </div>
    </div>
  );
};

export default Header;
