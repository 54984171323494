import React from "react";
import personal from "../personal.png";

const About = () => {
  return (
    <div>
      <section
        id="about_me_section_tobe_linked"
        className="about d-flex justify-content-center align-items-center"
      >
        <div className="container">
          <div className="row  gx-md-4 d-flex align-items-center justify-content-center">
            <div className="about-image col-md-4 mb-5">
              <img
                src={personal}
                className="img-fluid"
                alt="Sarah's profile"
              ></img>
            </div>
            <div className="about-text col-md-8 text-center">
              <p>
                Hi! I am Xiaoting Li. I am currently pursuing a Computer
                Information Technology program at the British Columbia Institute
                of Technology (BCIT), specializing in both software development
                and IT infrastructure. With a background in Marine Engineering,
                holding both Bachelor’s and Master’s degrees, I have a strong
                foundation in problem-solving and analytical skills. With 7
                years of professional experience in the legal-relevant industry,
                I have honed a strong work ethic grounded in integrity and
                responsibility. As I transition to a new phase of my career in
                Canada, I am eager to leverage my professional background and my
                new expertise.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
